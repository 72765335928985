import { Check, Close, Delete, Edit } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useChargerInstallationCosts } from "api/terminal/installation-cost";
import {
  postTerminalScenario,
  useCreateScenarioData,
} from "api/terminal/scenarios";
import { useVehicles } from "api/terminal/vehicles";
import { ChartData, ChartOptions } from "chart.js";
import ExcelJS from "exceljs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useAppSelector } from "redux/store";
import { Terminal, TerminalScenario } from "types/terminal";
import {
  ChargerData,
  FleetMixVehicle,
  VehicleData,
} from "types/terminal-scenario";
import { NewTerminalVehicleType } from "types/vehicle-types";
import { generateTimeLabels } from "utils/time";
import { downloadUtilityRate } from "../download";
import {
  PG_AND_E_UTILITY_RATE,
  SAMPLE_UTILITY_RATE,
} from "../SampleUtilityRate";

import {
  uploadNewUtilityRateStructure,
  useSetDefaultUtilityRate,
  useUtilityRatesForOrganizations,
} from "api/terminal/utility-rates";
import { Section } from "components/Section";
import { UtilityRateStructureNew } from "types/terminal-financial";
import { UtilityRatesNew } from "types/utility-rate";
import { useAccessToken } from "utils/get-access-token";
import { MONTH, MONTHSHORT } from "utils/month";

export type BasicDialogProps = {
  DialogProps: DialogProps;
  selectedTerminal: {
    id: number;
    name: string;
  };
  selectedFacility: {
    id: number;
    name: string;
  };
  terminals?: Terminal[];
  organizationId: number;
  refetchData: () => void;
};

const VEHICLE_REPLACEMENT_LIFECYCLE_YEARS = 12;
const BATTERY_REPLACEMENT_LIFECYCLE_YEARS = 6;

const tableColumns = {
  "2": [
    "Make",
    "Model",
    "Battery Size (KWH)",
    "Battery Charge Rate",
    "Price ($)",
    "Buy America Compliant?",
    "Action",
  ],
  "3": [
    "Make - Model",
    "Charge Rate (KW)",
    "Voltage (V)",
    "Price ($)",
    "Estimated Unit Installation Cost($)",
    "Action",
  ],
  "4": [
    "Utility rate",
    "Generation Charge",
    "Transmission Charge",
    "Distribution Charge",
  ],
  "4.2": [
    "PPA rate",
    "Generation Charge",
    "Transmission Charge",
    "Distribution Charge",
  ],
};

interface ConfigurationError {
  name: boolean;
  vehicleType: boolean;
  fleetSize: boolean;
  vehiclesPerShift: boolean;
  downTime: boolean;
  isError: boolean;
  message: String;
  evOptions: boolean;
  chargerOptions: boolean;
  utilityFile: boolean;
  isWarning: boolean;
  utilityName: boolean;
  rateConfirm: boolean;
}

export default function AddScenarioDialog({
  DialogProps,
  selectedTerminal,
  selectedFacility,
  terminals,
  organizationId,
  refetchData,
}: BasicDialogProps) {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>();
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [submitButtonTooltip, setSubmitButtonTooltip] = useState("");
  const [snackbarType, setSnackBarType] = useState<
    "success" | "info" | "error" | "warning"
  >("success");

  const [configuration, setConfiguration] = useState("new");
  const [utilityConfig, setUtilityConfig] = useState("new");
  const [rateConfirmation, setRateConfirmation] = useState(false);

  const [scenarioId, setScenarioId] = useState("");
  const [configurationName, setConfigurationName] = useState("");
  const [fetchVehicleTypes, setFetchVehicleTypes] = useState(true);
  const [prevScenarios, setPrevScenarios] = useState<TerminalScenario[]>([]);
  const [selectedVehicleTypeId, setSelectedVehicleTypeId] = useState(0);
  const [table1Data, setTable1Data] = useState<any[][]>();
  const [table2Data, setTable2Data] = useState<any[][]>();
  const [utilityRateUploadData, setUtilityRateUploadData] =
    useState<UtilityRatesNew>({
      name: "",
      demandCharges: [],
      demandRates: [],
      energyCharges: [],
      energyRates: [],
      fixedMonthlyFees: 0,
    });
  const [newRowData, setNewRowData] = useState<any[]>(Array(6).fill(undefined));
  const [editPrice, setEditPrice] = useState<number | null>(null);
  const [newPrice, setNewPrice] = useState<number | null>(null);
  const [newChargeRate, setChargeRate] = useState<number | null>(null);
  const [newInstallationCost, setNewInstallationCost] = useState<number | null>(
    null
  );
  const [deleteTableEntry, setDeleteTableEntry] = useState<number | null>(null);
  const [newTerminalScenario, setNewTerminalScenario] = useState<any>(null);
  const [vehicleTypes, setVehicleTypes] = useState<
    NewTerminalVehicleType[] | undefined
  >([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [selectedUtilityRate, setSelectedUtilityRate] =
    useState<UtilityRateStructureNew>();
  const [utilityInformation, setUtilityInformation] =
    useState<UtilityRateStructureNew>({
      fixedMonthlyFees: 0,
      name: "",
      id: 0,
      isDefault: false,
      demandCharges: [],
      demandRates: [],
      energyCharges: [],
      energyRates: [],
      organizationId: 0,
    });

  const utilityFileElement = useRef<HTMLInputElement>(null);
  const { utilityRates } = useUtilityRatesForOrganizations(selectedTerminal.id);
  const { setDefaultUtilityRate, loadingDefaultUtilityRate, errorUtilityRate } =
    useSetDefaultUtilityRate();

  const [hourData, setHourData] = useState<number[]>(
    Array.from({ length: 48 }, () => 1)
  );
  const [allowedChargingHours, setAllowedChargingHours] = useState<number[]>(
    Array.from({ length: 48 }, () => 1)
  );

  const [vehicleInfo, setVehicleInfo] = useState<FleetMixVehicle>({
    id: undefined,
    iceReferenceVehicle: "None",
  });

  const [utilityRateFile, setUtilityRateFile] = useState<string>("");

  const [configurationError, setConfigurationError] =
    useState<ConfigurationError>({
      isError: false,
      downTime: false,
      name: false,
      fleetSize: false,
      message: "",
      vehiclesPerShift: false,
      vehicleType: false,
      evOptions: false,
      chargerOptions: false,
      utilityFile: false,
      isWarning: false,
      utilityName: false,
      rateConfirm: false,
    });
  const {
    createScenarioData,
    loadingCreateScenarioData,
    errorLoadingCreateScenarioData,
    refetch,
  } = useCreateScenarioData(
    selectedTerminal.id,
    selectedFacility.id,
    scenarioId !== "" ? Number(scenarioId) : undefined
  );

  const { vehicles } = useVehicles(selectedVehicleTypeId);
  const { calculatedInstallationCost } =
    useChargerInstallationCosts(newChargeRate);
  const { getToken } = useAccessToken();

  //Redux handler
  const financialControls = useAppSelector((store) => store.financial);

  useEffect(() => {
    const selectedTerminalObject = terminals?.find(
      (terminal) => terminal.id === selectedTerminal.id
    );

    if (selectedTerminalObject) {
      const selectedFacilityObject =
        selectedTerminalObject.terminalFacilities.find(
          (facility) => facility.id === selectedFacility.id
        );

      if (selectedFacilityObject) {
        setPrevScenarios(selectedFacilityObject.terminalScenarios);
      }
    }
  }, [terminals, selectedTerminal, selectedFacility]);

  useEffect(() => {
    if (selectedVehicleTypeId > 0) {
      setFetchVehicleTypes(false);
    }
    const newData = createScenarioData?.newTerminalScenarioData;
    const chartData = newData?.shiftSchedule.length
      ? newData?.shiftSchedule
      : Array.from({ length: 48 }, () => 1);

    const chargingHourChartData = newData?.allowedChargingHours.length
      ? newData?.allowedChargingHours
      : Array.from({ length: 48 }, () => 1);

    setConfigurationName(newData?.scenarioName ?? "");
    setNewTerminalScenario(newData);
    setVehicleTypes(createScenarioData?.vehicleTypes);
    setHourData(chartData);
    setAllowedChargingHours(chargingHourChartData);

    setSelectedVehicleTypeId(newData?.fleetMix.vehicleTypeId ?? 0);

    setVehicleInfo({
      ...vehicleInfo,
      id: newData?.fleetMix.vehicleTypeId ?? undefined,
    });

    setNewTerminalScenario((prevData: any) => ({
      ...prevData,
      shiftSchedule: chartData,
      allowedChargingHours: chargingHourChartData,
      chargerMaintenanceCostPct:
        (newData?.chargerMaintenanceCostPct ?? 0) * 100,
      fleetMix: {
        ...prevData?.fleetMix,
        iceVehicleDowntime: (newData?.fleetMix?.iceVehicleDowntime ?? 0) * 100,
        evExpectedDowntime: (newData?.fleetMix?.evExpectedDowntime ?? 0) * 100,
        vehicleMaintenanceCostPct:
          (newData?.fleetMix?.vehicleMaintenanceCostPct ?? 0) * 100,
        iceVehicleMaintenanceCostPct:
          (newData?.fleetMix?.iceVehicleMaintenanceCostPct ?? 0) * 100,
      },
    }));

    const { evOptions, chargers, utilityRates, ppaRates } =
      handleTableData(newData);
    setTable1Data([evOptions, chargers, utilityRates]);
    setTable2Data(ppaRates);
  }, [scenarioId, createScenarioData]);

  useEffect(() => {
    if (utilityRates.length > 0) {
      setUtilityConfig("use");
      const defaultUtility =
        utilityRates.find((ut) => ut.isDefault) || utilityRates[0];
      setUtilityInformation(defaultUtility);
      setSelectedUtilityRate(defaultUtility);
    }
  }, [utilityRates]);

  useEffect(() => {
    if (fetchVehicleTypes && vehicles.length) {
      const iceVehicle = vehicles.find((vehicle) => !vehicle.isEV);

      setVehicleInfo({
        ...vehicleInfo,
        iceReferenceVehicle: iceVehicle?.name ?? "None",
      });

      const evVehicles = vehicles.filter((vehicle) => vehicle.isEV);
      const evVehiclesTableData = evVehicles.map((vehicle) => [
        vehicle.id,
        vehicle.make,
        vehicle.model,
        vehicle.batteryCapacityKwh,
        vehicle.batteryMaxChargeRateKw,
        vehicle.priceUsd,
        vehicle.buyAmericaCompliance,
      ]);

      const remainingData = table1Data?.slice(1) || [];
      setTable1Data([evVehiclesTableData, ...remainingData]);
      setNewTerminalScenario((prevData: any) => ({
        ...prevData,
        fleetMix: {
          ...prevData.fleetMix,
          iceVehicleId: iceVehicle?.id,
          evOptions: evVehicles.map(
            ({ isEV, organizationId, ...rest }) => rest
          ),
        },
      }));
    }
    setFetchVehicleTypes(true);
  }, [vehicles]);

  useEffect(() => {
    setNewRowData((prevData) => {
      const newData = [...prevData];
      newData[5] = newChargeRate ? calculatedInstallationCost : null;
      return newData;
    });
  }, [calculatedInstallationCost, newChargeRate]);

  const filterShiftCapableVehicles = useCallback((): any[] => {
    const shiftSchedule = newTerminalScenario?.shiftSchedule;
    const iceFuelConsumption =
      newTerminalScenario?.fleetMix.iceVehicleFuelConsumption;
    const selectedVehicleType = vehicleTypes?.find(
      (vehicleType) => vehicleType.id === selectedVehicleTypeId
    );

    if (!shiftSchedule || !iceFuelConsumption || !selectedVehicleType) {
      return newTerminalScenario?.fleetMix?.evOptions;
    }

    // Get the longest shift
    let extendedSchedule = shiftSchedule.concat(shiftSchedule);
    let longestShift = 0;
    let currentShift = 0;
    for (let i = 0; i < extendedSchedule.length; i++) {
      if (extendedSchedule[i] === 1) {
        currentShift++;
        longestShift = Math.max(longestShift, currentShift);
      } else {
        currentShift = 0;
      }
    }
    longestShift = Math.min(longestShift, 48);

    // Calculate required battery capacity for EV to handle shift length
    const { iceEfficiency, evEfficiency } = selectedVehicleType;
    const hourlyEnergyConsumption =
      ((iceFuelConsumption * iceEfficiency) / evEfficiency) * 37.12;
    const requiredBatteryCapacity =
      (hourlyEnergyConsumption / 2) * longestShift;

    // Filter for EVs that can handle the shift
    return newTerminalScenario?.fleetMix?.evOptions.filter(
      (evVehicle: {
        id: number | null;
        make: string;
        model: string;
        batteryCapacityKwh: number;
        batteryMaxChargeRateKw: number;
        priceUsd: number;
        buyAmericaCompliance: boolean;
      }) =>
        ((100 - 2 * newTerminalScenario?.fleetMix.evBatteryBuffer) / 100) *
          evVehicle.batteryCapacityKwh >=
        requiredBatteryCapacity
    );
  }, [newTerminalScenario, selectedVehicleTypeId, vehicleTypes]);

  useEffect(() => {
    if (
      !newTerminalScenario?.fleetMix?.evOptions ||
      newTerminalScenario?.fleetMix?.evOptions.length === 0
    ) {
      setErrorMessage(
        "No available vehicle can complete the longest shift. We will assign additional vehicles to complete this shift after the initial vehicles are done working."
      );
      return;
    }

    if (newTerminalScenario?.allowMidShiftCharging || !selectedVehicleTypeId) {
      return setErrorMessage(undefined);
    }

    const shiftCapableEvVehicles = filterShiftCapableVehicles();
    if (!shiftCapableEvVehicles.length) {
      setErrorMessage(
        "No available vehicle can complete the longest shift. We will assign additional vehicles to complete this shift after the initial vehicles are done working."
      );
    } else {
      setErrorMessage(undefined);
    }
  }, [
    selectedVehicleTypeId,
    newTerminalScenario?.shiftSchedule,
    newTerminalScenario?.allowMidShiftCharging,
    newTerminalScenario?.fleetMix.iceVehicleFuelConsumption,
    filterShiftCapableVehicles,
    newTerminalScenario?.fleetMix?.evOptions,
  ]);

  useEffect(() => {
    if (
      newTerminalScenario?.fleetMix.fleetSize &&
      newTerminalScenario?.fleetMix.vehiclesPerShift &&
      newTerminalScenario?.fleetMix.iceVehicleDowntime
    ) {
      const iceFleetSize = newTerminalScenario.fleetMix.fleetSize;
      const vehiclesPerShift = newTerminalScenario.fleetMix.vehiclesPerShift;
      const iceVehicleDowntime =
        newTerminalScenario.fleetMix.iceVehicleDowntime;

      if (iceFleetSize > vehiclesPerShift * (1 + iceVehicleDowntime / 100)) {
        setConfigurationError({ ...configurationError, isWarning: true });
        return;
      }
      setConfigurationError({ ...configurationError, isWarning: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    newTerminalScenario?.fleetMix.fleetSize,
    newTerminalScenario?.fleetMix.iceVehicleDowntime,
    newTerminalScenario?.fleetMix.vehiclesPerShift,
  ]);

  const handleTableData = (newData: any) => {
    const evOptions = newData?.fleetMix.evOptions.map(
      (vehicle: {
        id: number | null;
        make: string;
        model: string;
        batteryCapacityKwh: number;
        batteryMaxChargeRateKw: number;
        priceUsd: number;
        buyAmericaCompliance: boolean;
      }) => [
        vehicle.id,
        vehicle.make,
        vehicle.model,
        vehicle.batteryCapacityKwh,
        vehicle.batteryMaxChargeRateKw,
        vehicle.priceUsd,
        vehicle.buyAmericaCompliance,
      ]
    );

    const chargers = newData?.chargerOptions.map(
      (option: {
        id: number;
        make: string;
        model: string;
        chargeRateKw: number;
        voltage: number;
        priceUsd: number;
        installationCost: number;
      }) => [
        option.id,
        `${option.make} - ${option.model}`,
        option.chargeRateKw,
        option.voltage,
        option.priceUsd,
        option.installationCost,
      ]
    );

    const updatedRates = newData?.utilityRateStructure;
    const utilityRates = [
      [
        `${updatedRates?.name} - energy`,
        updatedRates?.generationChargePricePerKwh,
        updatedRates?.transmissionChargePricePerKwh,
        updatedRates?.distributionChargePricePerKwh,
      ],
      [
        `${updatedRates?.name} - demand`,
        updatedRates?.generationDemandChargePricePerKw,
        updatedRates?.transmissionDemandChargePricePerKw,
        updatedRates?.distributionDemandChargePricePerKw,
      ],
    ];

    const ppaRates = [
      [
        `${updatedRates?.name} - energy`,
        updatedRates?.ppaGenerationChargePricePerKwh,
        updatedRates?.ppaTransmissionChargePricePerKwh,
        updatedRates?.ppaDistributionChargePricePerKwh,
      ],
      [
        `${updatedRates?.name} - demand`,
        updatedRates?.ppaGenerationDemandChargePricePerKw,
        updatedRates?.ppaTransmissionDemandChargePricePerKw,
        updatedRates?.ppaDistributionDemandChargePricePerKw,
      ],
    ];

    return { evOptions, chargers, utilityRates, ppaRates };
  };

  const handleVehicleTypeChange = (vehicleTypeId: number) => {
    setSelectedVehicleTypeId(vehicleTypeId);
    setVehicleInfo({
      ...vehicleInfo,
      id: vehicleTypeId,
    });
    const iceReferenceFuelConsumption = vehicleTypes?.filter(
      (vehicleType: { id: number }) => vehicleType.id === vehicleTypeId
    )[0].iceReferenceFuelConsumption;
    setNewTerminalScenario((prevData: any) => ({
      ...prevData,
      fleetMix: {
        ...prevData.fleetMix,
        iceVehicleFuelConsumption: Number(iceReferenceFuelConsumption),
        vehicleTypeId,
      },
    }));
  };

  const handleRateConfirmationClick = async () => {
    if (!utilityInformation.name) {
      setConfigurationError({
        ...configurationError,
        utilityName: true,
        message: "Please enter utility rate name.",
      });
      return;
    }

    if (
      utilityConfig === "new" &&
      utilityRates.find(
        (ut) => ut.name.toLowerCase() === utilityInformation.name.toLowerCase()
      )
    ) {
      setConfigurationError({
        ...configurationError,
        utilityName: true,
        message: "Utility name already exists.",
      });
      return;
    }
    setShowSnackbar(true);
    setSnackbarMessage("Rate are successfully confirmed.");
    setSnackBarType("success");

    setTimeout(() => setShowSnackbar(false), 2000);
    setConfigurationError({ ...configurationError, rateConfirm: false });
    setRateConfirmation(true);
  };

  const handleSubmit = async () => {
    if (!rateConfirmation) {
      setConfigurationError({ ...configurationError, rateConfirm: true });
      return;
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    // const utilityRate = utilityInformation;
    // if (utilityConfig === "new") {
    //   utilityRate.id = 0;
    // }

    const modifiedTerminalScenario = {
      ...newTerminalScenario,
      chargerMaintenanceCostPct:
        newTerminalScenario?.chargerMaintenanceCostPct / 100,
      ppaRate: financialControls.ppaRate,
      utilityRateStructureId: selectedUtilityRate?.id,
      fleetMix: newTerminalScenario?.fleetMix
        ? {
            ...newTerminalScenario.fleetMix,
            iceVehicleDowntime:
              newTerminalScenario.fleetMix.iceVehicleDowntime / 100,
            evExpectedDowntime:
              newTerminalScenario.fleetMix.evExpectedDowntime / 100,
            vehicleMaintenanceCostPct:
              newTerminalScenario.fleetMix.vehicleMaintenanceCostPct / 100,
            iceVehicleMaintenanceCostPct:
              newTerminalScenario.fleetMix.iceVehicleMaintenanceCostPct / 100,
          }
        : undefined,
    };
    // Set evOptions and chargerOptions id to null if any of the fields were modified
    modifiedTerminalScenario.fleetMix.evOptions.map((evOption: any) => {
      if (evOption.id) {
        const originalVehicle =
          vehicles.find((vehicle) => vehicle.id === evOption.id) ??
          createScenarioData?.newTerminalScenarioData?.fleetMix.evOptions.find(
            (vehicle) => vehicle.id === evOption.id
          );
        if (originalVehicle) {
          Object.keys(evOption).forEach((key) => {
            if (
              evOption[key] !==
              originalVehicle[key as keyof typeof originalVehicle]
            ) {
              evOption["id"] = null;
            }
          });
        }
      }
      return evOption;
    });
    modifiedTerminalScenario.chargerOptions.map((chargerOption: any) => {
      if (chargerOption.id) {
        const originalCharger =
          createScenarioData?.newTerminalScenarioData?.chargerOptions.find(
            (charger) => charger.id === chargerOption.id
          );
        if (originalCharger) {
          Object.keys(chargerOption).forEach((key) => {
            if (
              chargerOption[key] !==
              originalCharger[key as keyof typeof originalCharger]
            ) {
              chargerOption["id"] = null;
            }
          });
        }
      }
      return chargerOption;
    });

    const apiToken = await getToken();

    if (utilityConfig === "new") {
      const response = (await uploadNewUtilityRateStructure(
        utilityRateUploadData,
        selectedTerminal.id,
        apiToken
      )) as UtilityRatesNew;
      modifiedTerminalScenario.utilityRateStructureId = response.id;
    }

    postTerminalScenario(
      selectedTerminal.id,
      selectedFacility.id,
      apiToken,
      modifiedTerminalScenario
    )
      .then(() => {
        setShowSnackbar(true);
        setSnackbarMessage(
          "Configuration created successfully! Running optimization..."
        );
        setSnackBarType("success");
        setSubmitButtonDisabled(true);
        setSubmitButtonTooltip("Configuration already submitted");
        refetchData();
      })
      .catch((error) => {
        setShowSnackbar(true);
        setSnackbarMessage("Error creating configuration");
        setSnackBarType("error");
      });

    setTimeout(() => {
      DialogProps.onClose?.({}, "backdropClick");
    }, 6000);
  };

  const disableSubmitBtn = useMemo(() => {
    let disability = false;
    if (!configurationName) disability = true;
    if (!newTerminalScenario?.planningHorizonYears) disability = true;
    if (!newTerminalScenario?.fleetMix?.vehicleTypeId) disability = true;
    if (!newTerminalScenario?.fleetMix?.fleetSize) disability = true;
    if (!newTerminalScenario?.fleetMix?.vehiclesPerShift) disability = true;
    if (!vehicleInfo.id) disability = true;
    if (submitButtonDisabled) disability = true;
    if (!newTerminalScenario?.fleetMix?.evOptions?.length) disability = true;
    if (!newTerminalScenario?.chargerOptions?.length) disability = true;
    return disability;
  }, [
    configurationName,
    newTerminalScenario?.fleetMix,
    newTerminalScenario?.planningHorizonYears,
    newTerminalScenario?.chargerOptions,
    vehicleInfo,
    submitButtonDisabled,
  ]);

  const handleExistingFleetSizeChange = (e: any) => {
    if (
      newTerminalScenario?.fleetMix.vehiclesPerShift &&
      newTerminalScenario?.fleetMix.vehiclesPerShift >
        Math.max(parseInt(e.target.value), 0)
    ) {
      setNewTerminalScenario((prevData: any) => ({
        ...prevData,
        fleetMix: {
          ...prevData.fleetMix,
          fleetSize: Math.max(parseInt(e.target.value), 0),
          vehiclesPerShift: Math.max(parseInt(e.target.value), 0),
        },
      }));
    } else {
      setNewTerminalScenario((prevData: any) => ({
        ...prevData,
        fleetMix: {
          ...prevData.fleetMix,
          fleetSize: Math.max(parseInt(e.target.value), 0),
          vehiclesPerShift: e.target.value
            ? newTerminalScenario?.fleetMix.vehiclesPerShift || 0
            : 0,
        },
      }));
    }
  };

  const handleVehiclesPerShiftChange = (e: any) => {
    setNewTerminalScenario((prevData: any) => ({
      ...prevData,
      fleetMix: {
        ...prevData.fleetMix,
        vehiclesPerShift: Math.max(
          Math.min(
            parseInt(e.target.value),
            newTerminalScenario?.fleetMix.fleetSize
          ),
          0
        ),
      },
    }));
  };

  const handleHourClick = (index: number): void => {
    const newData = [...hourData];
    newData[index] = (newData[index] + 1) % 3;
    setHourData(newData);
    setNewTerminalScenario((prevData: any) => ({
      ...prevData,
      shiftSchedule: newData,
    }));
  };

  const handleChargingAllowedHourClick = (index: number): void => {
    const newData = [...allowedChargingHours];
    newData[index] = (newData[index] + 1) % 2;
    setAllowedChargingHours(newData);

    setNewTerminalScenario((prevData: any) => ({
      ...prevData,
      allowedChargingHours: newData,
    }));
  };

  const terminalNameText = useMemo(() => {
    return `Terminal: ${selectedTerminal.name}`;
  }, [selectedTerminal]);
  const facilityNameText = useMemo(() => {
    return `Facility: ${selectedFacility.name}`;
  }, [selectedFacility]);

  const chartLabels = generateTimeLabels();

  const chartData: ChartData<"bar"> = {
    labels: chartLabels,
    datasets: [
      {
        label: "Unused",
        data: hourData.map((value) => (value === 0 ? 1 : 0)),
        backgroundColor: "white",
        borderColor: "#000",
        borderWidth: 1,
        stack: "stack",
        barPercentage: 1,
        categoryPercentage: 1,
      },
      {
        label: "Shift",
        data: hourData.map((value) => (value === 1 ? 1 : 0)),
        backgroundColor: "#05C2CC",
        borderColor: "#000",
        borderWidth: 1,
        stack: "stack",
        barPercentage: 1,
        categoryPercentage: 1,
      },
      {
        label: "Break",
        data: hourData.map((value) => (value === 2 ? 1 : 0)),
        backgroundColor: "#FDBE02",
        borderWidth: 1,
        borderColor: "#000",
        stack: "stack",
        barPercentage: 1,
        categoryPercentage: 1,
      },
    ],
  };

  const chargingHourChartData: ChartData<"bar"> = {
    labels: chartLabels,
    datasets: [
      {
        label: "Yes",
        data: allowedChargingHours.map((value) => (value === 1 ? 1 : 0)),
        backgroundColor: "#50C878",
        borderColor: "#000",
        borderWidth: 1,
        stack: "stack",
        barPercentage: 1,
        categoryPercentage: 1,
      },
      {
        label: "No",
        data: allowedChargingHours.map((value) => (value === 0 ? 1 : 0)),
        backgroundColor: "#D2042D",
        borderColor: "#000",
        borderWidth: 1,
        stack: "stack",
        barPercentage: 1,
        categoryPercentage: 1,
      },
    ],
  };

  const chartOptions: ChartOptions<"bar"> = {
    scales: {
      x: {
        labels: chartLabels,
        display: true,
        grid: {
          color: "#000",
          drawTicks: false,
          drawOnChartArea: true,
        },
        beginAtZero: true,
        ticks: {
          autoSkip: false,
          callback(tickValue: any, index: number) {
            if (index % 2 === 0) {
              return chartLabels[index];
            }
            return "";
          },
        },
      },
      y: {
        display: false,
      },
    },
    animation: {
      duration: 0,
    },
    onHover: (event, chartElement) => {
      const target = event.native?.target as HTMLElement;
      target.style.cursor = chartElement[0] ? "pointer" : "default";
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const index = elements[0].index as number;
        handleHourClick(index);
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          title(context: any) {
            const currentHour = Math.floor(context[0].dataIndex);
            const nextHour = (currentHour + 1) % 48;
            return `Hour: ${chartLabels[currentHour]} to ${chartLabels[nextHour]}`;
          },
          label(context: any) {
            return [`${context.dataset.label}`];
          },
        },
      },
    },
  };

  const chargingHourChartOptions: ChartOptions<"bar"> = {
    // maintainAspectRatio: false,
    scales: {
      x: {
        labels: chartLabels,
        grid: {
          color: "#000",
          drawTicks: false,
          drawOnChartArea: true,
        },
        beginAtZero: false,
        ticks: {
          autoSkip: false,
          callback(tickValue: any, index: number) {
            if (index % 2 === 0) {
              return chartLabels[index];
            }
            return "";
          },
        },
      },
      y: {
        display: false,
      },
    },
    animation: {
      duration: 0,
    },
    onHover: (event, chartElement) => {
      const target = event.native?.target as HTMLElement;
      target.style.cursor = chartElement[0] ? "pointer" : "default";
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const index = elements[0].index as number;
        handleChargingAllowedHourClick(index);
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          title(context: any) {
            const currentHour = Math.floor(context[0].dataIndex);
            const nextHour = (currentHour + 1) % 48;
            return `Hour: ${chartLabels[currentHour]} to ${chartLabels[nextHour]}`;
          },
          label(context: any) {
            return [`${context.dataset.label}`];
          },
        },
      },
    },
  };

  const handleChangeVehicleReplacementLifecycle = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!newTerminalScenario?.fleetMix.batteryReplacementLifecycleYears)
      setNewTerminalScenario((prevData: any) => ({
        ...prevData,
        fleetMix: {
          ...prevData.fleetMix,
          vehicleReplacementLifecycleYears: getCorrectInt(
            e.target.value,
            BATTERY_REPLACEMENT_LIFECYCLE_YEARS + 1,
            24
          ),
        },
      }));
    else {
      setNewTerminalScenario((prevData: any) => ({
        ...prevData,
        fleetMix: {
          ...prevData.fleetMix,
          vehicleReplacementLifecycleYears: getCorrectInt(
            e.target.value,
            2,
            24
          ),
        },
      }));
    }
    if (
      newTerminalScenario?.fleetMix.batteryReplacementLifecycleYears &&
      e.target.value !== "" &&
      parseInt(e.target.value) <=
        newTerminalScenario?.fleetMix.batteryReplacementLifecycleYears
    ) {
      setNewTerminalScenario((prevData: any) => ({
        ...prevData,
        fleetMix: {
          ...prevData.fleetMix,
          batteryReplacementLifecycleYears:
            (getCorrectInt(e.target.value, 2, 24) || 2) - 1,
        },
      }));
    }
  };

  const handleChangeBatteryReplacementLifecycle = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!newTerminalScenario?.fleetMix.vehicleReplacementLifecycleYears) {
      setNewTerminalScenario((prevData: any) => ({
        ...prevData,
        fleetMix: {
          ...prevData.fleetMix,
          batteryReplacementLifecycleYears: getCorrectInt(
            e.target.value,
            1,
            VEHICLE_REPLACEMENT_LIFECYCLE_YEARS - 1
          ),
        },
      }));
    } else {
      setNewTerminalScenario((prevData: any) => ({
        ...prevData,
        fleetMix: {
          ...prevData.fleetMix,
          batteryReplacementLifecycleYears: getCorrectInt(
            e.target.value,
            1,
            23
          ),
        },
      }));
    }
    if (
      newTerminalScenario?.fleetMix.vehicleReplacementLifecycleYears &&
      e.target.value !== "" &&
      parseInt(e.target.value) >=
        newTerminalScenario?.fleetMix.vehicleReplacementLifecycleYears
    ) {
      setNewTerminalScenario((prevData: any) => ({
        ...prevData,
        fleetMix: {
          ...prevData.fleetMix,
          vehicleReplacementLifecycleYears:
            (getCorrectInt(e.target.value, 1, 23) || 0) + 1,
        },
      }));
    }
  };
  const getCorrectInt = (value: string, min: number, max: number) => {
    if (value === "") {
      return undefined;
    }
    return Math.max(Math.min(parseInt(value), max), min);
  };
  const getCorrectFloat = (value: string, min: number, max: number) => {
    if (value === "") {
      return undefined;
    }
    return Math.max(Math.min(parseFloat(value), max), min);
  };

  const handleChangeConfiguration = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfiguration(event.target.value);
  };

  const handleChangeAllowMidShiftCharging = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewTerminalScenario((prevData: any) => ({
      ...prevData,
      allowMidShiftCharging: event.target.value === "true" ? true : false,
    }));
  };

  const handleInputChange = (
    value: string,
    rowIndex: number,
    columnIndex: number,
    setFunction: any,
    tableIdentifier: "table1" | "table2"
  ) => {
    setFunction((prevData: any) => {
      let newData = [...prevData];

      if (tableIdentifier === "table1") {
        newData[2][rowIndex] = [...newData[2][rowIndex]];
        newData[2][rowIndex][columnIndex] = value;
      } else {
        newData[rowIndex] = [...newData[rowIndex]];
        newData[rowIndex][columnIndex] = value;
      }
      return newData;
    });
  };

  const handleDeleteTableEntry = (index: number) => {
    const targetData = table1Data?.[step - 2];
    const entryToDelete = targetData?.[index];

    const updatedData = (targetData ?? []).filter((_, i) => i !== index);
    const newData = (table1Data ?? []).map((arr, i) =>
      i === step - 2 ? updatedData : arr
    );

    if (step === 2) {
      const updatedEVs = newTerminalScenario?.fleetMix?.evOptions.filter(
        (option: any) => {
          if (option.id !== undefined) {
            return option.id !== entryToDelete[0];
          } else {
            return !(
              option.make === entryToDelete[1] &&
              option.model === entryToDelete[2]
            );
          }
        }
      );

      setNewTerminalScenario((prevData: any) => ({
        ...prevData,
        fleetMix: {
          ...prevData.fleetMix,
          evOptions: updatedEVs,
        },
      }));
    }

    if (step === 3) {
      const updatedChargers = newTerminalScenario?.chargerOptions.filter(
        (option: any) => {
          if (option.id !== undefined) {
            return option.id !== entryToDelete[0];
          } else {
            return !(`${option.make} - ${option.model}` === entryToDelete[1]);
          }
        }
      );

      setNewTerminalScenario((prevData: any) => ({
        ...prevData,
        chargerOptions: updatedChargers,
      }));
    }

    setTable1Data(newData);
    setDeleteTableEntry(null);
  };

  const handleSaveNewEntry = () => {
    setEditMode(false);

    let updatedData = [...(table1Data ?? [])];

    if (step === 3) {
      const makeModelCombined = `${newRowData[0]} - ${newRowData[1]}`;
      const adjustedNewRowData = [
        undefined,
        makeModelCombined,
        ...newRowData.slice(2),
      ];
      updatedData[step - 2].unshift(adjustedNewRowData);
    } else {
      newRowData.unshift(undefined);
      updatedData[step - 2].unshift(newRowData);
    }
    setTable1Data(updatedData);
    setNewRowData(Array(step === 3 ? 5 : 4).fill(undefined));

    if (step === 2) {
      const updatedEntry: VehicleData = {
        id: newRowData[0],
        make: newRowData[1],
        model: newRowData[2],
        batteryCapacityKwh: Number(newRowData[3]),
        batteryMaxChargeRateKw: Number(newRowData[4]),
        priceUsd: Number(newRowData[5]),
        vehicleTypeId: selectedVehicleTypeId,
        buyAmericaCompliance: !!newRowData[6],
        isEV: true,
      };

      setNewTerminalScenario((prevData: any) => ({
        ...prevData,
        fleetMix: {
          ...prevData.fleetMix,
          evOptions: [...prevData.fleetMix.evOptions, updatedEntry],
        },
      }));
    }

    if (step === 3) {
      const updatedEntry: ChargerData = {
        id: undefined,
        make: newRowData[0],
        model: newRowData[1],
        chargeRateKw: Number(newRowData[2]),
        voltage: Number(newRowData[3]),
        priceUsd: Number(newRowData[4]),
        installationCost: Number(newRowData[5]),
        amperage: newRowData[6],
      };
      setNewTerminalScenario((prevData: any) => ({
        ...prevData,
        chargerOptions: [...prevData.chargerOptions, updatedEntry],
      }));
    }
  };

  const handleInputChangeForRowData = (
    e: { target: { value: any; checked?: any } },
    columnIndex: number
  ) => {
    let updatedNewRowData = [...newRowData];

    updatedNewRowData[columnIndex] = e.target.value;

    if (step === 2 && columnIndex === 5) {
      updatedNewRowData[columnIndex] = e.target.checked;
    }

    if (step === 2 && columnIndex === 2) {
      const quarterValue = Number(e.target.value) / 4;
      updatedNewRowData[3] =
        !isNaN(quarterValue) && isFinite(quarterValue) ? quarterValue : "";
    }

    if (step === 3 && columnIndex === 2) {
      setChargeRate(Number(e.target.value));
    }
    setNewRowData(updatedNewRowData);
  };

  const setDefaultUtilityRateAPI = async () => {
    if (selectedUtilityRate && selectedUtilityRate.id) {
      await setDefaultUtilityRate(organizationId, selectedUtilityRate.id);
      if (errorUtilityRate) {
        setShowSnackbar(true);
        setSnackbarMessage(`Error: ${errorUtilityRate.message}`);
        setSnackBarType("error");
      } else {
        setShowSnackbar(true);
        setSnackbarMessage("Default utility rates have been successfully set.");
        setSnackBarType("success");
      }
      setTimeout(() => setShowSnackbar(false), 3000);
    }
  };

  const handleEditPrice = (index: number) => {
    const updatedTableData = [...(table1Data?.[step - 2] ?? [])];
    const entryToUpdate = [...updatedTableData[index]];
    entryToUpdate[step === 2 ? 5 : 4] = newPrice;
    if (step === 2) {
      entryToUpdate[6] = entryToUpdate[6];
    } else if (step === 3) {
      entryToUpdate[5] = newInstallationCost;
    }
    updatedTableData[index] = entryToUpdate;
    setTable1Data((prevState) => {
      const newState = [...(prevState ?? [])];
      newState[step - 2] = updatedTableData;
      return newState;
    });

    if (step === 2) {
      const updatedEvOptions = newTerminalScenario?.fleetMix?.evOptions.map(
        (option: any) => {
          if (option.id === entryToUpdate[0]) {
            return {
              ...option,
              priceUsd: newPrice,
              buyAmericaCompliance: entryToUpdate[6],
            };
          }
          return option;
        }
      );

      setNewTerminalScenario((prevData: any) => ({
        ...prevData,
        fleetMix: {
          ...prevData.fleetMix,
          evOptions: updatedEvOptions,
        },
      }));
    }

    if (step === 3) {
      const updatedChargers = newTerminalScenario?.chargerOptions.map(
        (charger: any) => {
          if (charger.id === entryToUpdate[0]) {
            return {
              ...charger,
              id: undefined,
              priceUsd: newPrice,
              installationCost: newInstallationCost,
            };
          }
          return charger;
        }
      );

      setNewTerminalScenario((prevData: any) => ({
        ...prevData,
        chargerOptions: updatedChargers,
      }));
    }

    setNewPrice(null);
    setNewInstallationCost(null);
    setEditPrice(null);
  };

  const handleCancelNewEntry = () => {
    setEditMode(false);
    setNewRowData(Array(step === 2 ? 6 : 5).fill(undefined));
  };

  const handleNext = () => {
    if (step === 1) {
      if (!configurationName) {
        setConfigurationError({
          ...configurationError,
          name: true,
          isError: true,
          message: "",
        });
        return;
      }
      if (selectedVehicleTypeId === 0) {
        setConfigurationError({
          ...configurationError,
          vehicleType: true,
          isError: true,
          message: "Please select a vehicle type.",
        });
        return;
      }

      if (
        !newTerminalScenario?.fleetMix?.fleetSize ||
        newTerminalScenario.fleetMix.fleetSize === 0
      ) {
        setConfigurationError({
          ...configurationError,
          fleetSize: true,
          isError: true,
          message: "Existing ICE Fleet Size must be greater than 0.",
        });
        return;
      }

      if (
        !newTerminalScenario?.fleetMix?.vehiclesPerShift ||
        newTerminalScenario.fleetMix.vehiclesPerShift === 0
      ) {
        setConfigurationError({
          ...configurationError,
          vehiclesPerShift: true,
          isError: true,
          message: "Number of vehicles per shift must be greater than 0.",
        });
        return;
      }
    }

    if (step === 2) {
      const iceFleetSize = newTerminalScenario.fleetMix.fleetSize;
      const vehiclesPerShift = newTerminalScenario.fleetMix.vehiclesPerShift;
      const iceVehicleDowntime =
        newTerminalScenario.fleetMix.iceVehicleDowntime;
      if (iceFleetSize < vehiclesPerShift * (1 + iceVehicleDowntime / 100)) {
        setConfigurationError({ ...configurationError, downTime: true });
        return;
      }

      if (
        !newTerminalScenario.fleetMix.evOptions ||
        newTerminalScenario.fleetMix.evOptions.length === 0
      ) {
        setConfigurationError({
          ...configurationError,
          evOptions: true,
          isError: true,
          message: "Please add at least one vehicle before continuing.",
        });
        return;
      }
    }

    if (step === 3) {
      if (
        !newTerminalScenario.chargerOptions ||
        newTerminalScenario.chargerOptions.length === 0
      ) {
        setConfigurationError({
          ...configurationError,
          chargerOptions: true,
          isError: true,
          message: "Please add at least one chanrger before continuing.",
        });
        return;
      }
    }

    if (step < 4) {
      setEditMode(false);
      setDeleteTableEntry(null);
      setEditPrice(null);
      setNewRowData(Array(step === 1 ? 6 : 5).fill(undefined));
      setStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setEditMode(false);
      setDeleteTableEntry(null);
      setEditPrice(null);
      setNewRowData(Array(step === 3 ? 6 : 5).fill(undefined));
      setConfigurationError({
        ...configurationError,
        downTime: false,
        isError: false,
        message: "",
      });
      setStep((prevStep) => prevStep - 1);
    }
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const apiToken = await getToken();
    const file = event.target?.files?.[0];
    if (!file) {
      console.error("No file selected.");
      return;
    }

    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const buffer = e.target?.result as ArrayBuffer;
        if (!buffer) {
          console.error("Failed to read file content.");
          return;
        }

        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(buffer);
        const sheet = workbook.worksheets[0];
        if (!sheet) {
          console.error("No sheet found in the Excel file.");
          return;
        }

        const values = sheet.getSheetValues() as any[][];
        const result: UtilityRatesNew = {
          name: values[1]?.[2] || "",
          fixedMonthlyFees: parseFloat(values[2]?.[2]) || 0,
          energyRates: [],
          demandRates: [],
          energyCharges: [],
          demandCharges: [],
        };

        // Determine the end of the energy rates section
        let energyRatesEnd = 6; // Start from row 6
        while (
          values[energyRatesEnd]?.[1] ||
          values[energyRatesEnd]?.[2] ||
          values[energyRatesEnd]?.[3]
        ) {
          energyRatesEnd++;
        }

        // Extract Energy Rates
        for (let i = 6; i < energyRatesEnd; i++) {
          result.energyRates.push({
            period: parseFloat(values[i]?.[1]) || 0,
            tier: parseFloat(values[i]?.[2]) || 0,
            rates: parseFloat(values[i]?.[3]) || 0,
          });
        }

        // Extract Energy Charges
        const energyChargesStart = energyRatesEnd + 2;
        const energyChargesEnd = energyChargesStart + 12; // 12 months
        for (let i = energyChargesStart; i < energyChargesEnd; i++) {
          const row: number[] = [];
          for (let hour = 2; hour <= 25; hour++) {
            row.push(parseFloat(values[i]?.[hour]) || 0);
          }
          result.energyCharges.push(row);
        }

        // Determine the end of the demand rates section
        let demandRatesStart = energyChargesEnd + 3;
        let demandRatesEnd = demandRatesStart;
        while (
          values[demandRatesEnd]?.[1] ||
          values[demandRatesEnd]?.[2] ||
          values[demandRatesEnd]?.[3]
        ) {
          demandRatesEnd++;
        }

        // Extract Demand Rates
        for (let i = demandRatesStart; i < demandRatesEnd; i++) {
          result.demandRates.push({
            period: parseFloat(values[i]?.[1]) || 0,
            tier: parseFloat(values[i]?.[2]) || 0,
            rates: parseFloat(values[i]?.[3]) || 0,
          });
        }

        // Extract Demand Charges
        const demandChargesStart = demandRatesEnd + 2;
        const demandChargesEnd = demandChargesStart + 12; // 12 months
        for (let i = demandChargesStart; i < demandChargesEnd; i++) {
          const row: number[] = [];
          for (let hour = 2; hour <= 25; hour++) {
            row.push(parseFloat(values[i]?.[hour]) || 0);
          }
          result.demandCharges.push(row);
        }
        setUtilityRateUploadData(result);
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.error("Error while processing the file:", error);
    }
  };

  const handleUploadFileClick = () => {
    if (utilityFileElement.current) {
      utilityFileElement.current.click();
    }
  };

  return (
    <>
      <Dialog
        {...DialogProps}
        maxWidth="xl"
        fullWidth
        className="add-new-configuration-dialog"
      >
        {loadingCreateScenarioData ? (
          <div
            style={{ display: "grid", placeItems: "center", minHeight: 600 }}
          >
            <CircularProgress size={100} />
          </div>
        ) : errorLoadingCreateScenarioData ? (
          <Stack
            sx={{
              justifyContent: "center",
              alignItems: "center",
              minHeight: 600,
              color: "red",
            }}
            spacing={2}
          >
            <h3>An error occurred, please try again.</h3>
            <Button onClick={refetch} variant="contained" color="primary">
              Reload
            </Button>
          </Stack>
        ) : (
          <>
            <DialogTitle>
              <Stack
                mt={1}
                direction="row"
                justifyContent={"space-between"}
                spacing={4}
              >
                <Typography variant="h3">
                  <b>Create a New Configuration - Step&nbsp;{step} of 4</b>
                </Typography>

                {step === 1 ? (
                  <Typography sx={{ fontSize: "16px" }}>
                    <b>{terminalNameText}</b>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <b>{facilityNameText}</b>
                  </Typography>
                ) : (
                  <Typography variant="h2">{configurationName}</Typography>
                )}
              </Stack>
            </DialogTitle>
            <DialogContent>
              <Stack>
                <Stack spacing={2}>
                  {step === 1 && (
                    <Stack mt={0.6} direction={"row"} spacing={2}>
                      <TextField
                        required
                        label="Configuration Name"
                        sx={{ width: "36%" }}
                        size="small"
                        value={configurationName}
                        error={configurationError.name}
                        helperText={
                          configurationError.name
                            ? "Please Enter Configuration Name."
                            : ""
                        }
                        onChange={(e) => {
                          setConfigurationError({
                            ...configurationError,
                            name: false,
                            isError: false,
                            message: "",
                          });
                          setConfigurationName(e.target.value);
                          setNewTerminalScenario((prevData: any) => ({
                            ...prevData,
                            scenarioName: e.target.value,
                          }));
                        }}
                      />
                      <TextField
                        required
                        label="Planning Horizon (years)"
                        className="planning-horizon"
                        type="number"
                        size="small"
                        sx={{ width: "26%" }}
                        value={Math.max(
                          newTerminalScenario?.planningHorizonYears,
                          0
                        )}
                        onChange={(e) =>
                          setNewTerminalScenario((prevData: any) => ({
                            ...prevData,
                            planningHorizonYears:
                              getCorrectInt(e.target.value, 0, 24) || 0,
                          }))
                        }
                      />
                    </Stack>
                  )}

                  {step === 2 && (
                    <Stack direction="row" spacing={2}>
                      <Typography
                        sx={{ fontSize: "17px", fontWeight: 500, mt: 0.7 }}
                      >
                        <b> ICE Reference Vehicle:&nbsp;&nbsp;</b>
                        {vehicleInfo.iceReferenceVehicle}
                      </Typography>
                    </Stack>
                  )}

                  {step === 1 && (
                    <Stack direction="row">
                      <RadioGroup
                        row
                        value={configuration}
                        onChange={handleChangeConfiguration}
                      >
                        <FormControlLabel
                          value="new"
                          control={<Radio />}
                          label="Create a new configuration"
                        />
                        <FormControlLabel
                          value="copy"
                          control={<Radio />}
                          label="Copy an existing configuration"
                        />
                      </RadioGroup>

                      {configuration === "copy" && (
                        <FormControl
                          variant="outlined"
                          size="small"
                          sx={{
                            width: prevScenarios.length !== 0 ? "26%" : "26%",
                          }}
                        >
                          {prevScenarios.length !== 0 ? (
                            <>
                              <InputLabel>List of configurations</InputLabel>
                              <Select
                                label="List of configurations"
                                value={scenarioId}
                                onChange={(event) =>
                                  setScenarioId(event.target.value)
                                }
                              >
                                <MenuItem value={""}>None</MenuItem>
                                {prevScenarios.map((scenario) => (
                                  <MenuItem
                                    key={scenario.id}
                                    value={scenario.id}
                                  >
                                    {scenario.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </>
                          ) : (
                            <TextField
                              label="List of configurations"
                              value={"No configurations available"}
                              size="small"
                              disabled
                              sx={{ input: { cursor: "not-allowed" } }}
                              InputProps={{ style: { fontSize: "13px" } }}
                              InputLabelProps={{
                                style: { fontSize: "12px" },
                              }}
                            />
                          )}
                        </FormControl>
                      )}
                    </Stack>
                  )}

                  <Stack spacing={1}>
                    {step === 1 && (
                      <>
                        <Stack
                          spacing={2}
                          width="100%"
                          className="shift-information"
                        >
                          <Typography variant="h3">
                            Shift Information
                          </Typography>
                          <Bar
                            data={chartData}
                            options={chartOptions}
                            height={"40px"}
                          />
                        </Stack>

                        <Stack
                          spacing={2}
                          width="100%"
                          className="shift-information"
                        >
                          <Typography variant="h3">
                            Allowed Charging Hours
                          </Typography>
                          <Bar
                            data={chargingHourChartData}
                            options={chargingHourChartOptions}
                            height={"30px"}
                          />
                        </Stack>
                      </>
                    )}
                    {step === 1 && (
                      <Grid container alignItems={"center"} columnGap={2}>
                        <Grid item>
                          <Typography variant="h4">
                            Can vehicles be pulled mid-shift for charging?
                          </Typography>
                        </Grid>
                        <Grid item>
                          <RadioGroup
                            row
                            aria-labelledby="mid-shift-label"
                            name="mid-shift"
                            value={
                              newTerminalScenario?.allowMidShiftCharging ||
                              false
                            }
                            onChange={handleChangeAllowMidShiftCharging}
                          >
                            <FormControlLabel
                              value="true"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="false"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    )}
                    <Stack spacing={2} width={step === 1 ? "92%" : "99%"}>
                      {step === 1 && (
                        <>
                          <Typography variant="h3">Work Information</Typography>
                          <Stack
                            spacing={{ md: 1, lg: 2 }}
                            direction="row"
                            width="110%"
                            alignItems="center"
                          >
                            <FormControl
                              sx={{
                                width: "35%",
                                "& .MuiInputBase-input": {
                                  fontSize: "14px",
                                },
                              }}
                            >
                              <InputLabel
                                sx={{ fontSize: "14px" }}
                                id="light-duty-label"
                                size="small"
                                required
                              >
                                Vehicle Type
                              </InputLabel>
                              <Select
                                value={vehicleInfo.id}
                                labelId="light-duty-label"
                                label="Vehicle Type"
                                size="small"
                                error={configurationError.vehicleType}
                                onChange={(e) => {
                                  setConfigurationError({
                                    ...configurationError,
                                    vehicleType: false,
                                    isError: false,
                                    message: "",
                                  });
                                  handleVehicleTypeChange(
                                    Number(e.target.value)
                                  );
                                }}
                                required
                              >
                                {createScenarioData &&
                                  vehicleTypes?.map((vehicleType) => (
                                    <MenuItem
                                      value={vehicleType.id}
                                      key={vehicleType.id}
                                    >
                                      {vehicleType.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                            <Tooltip title="This buffer is used on the lower end and upper end of battery capacity">
                              <TextField
                                label="EV battery buffer (%)"
                                sx={{
                                  width: "30%",
                                }}
                                size="small"
                                required
                                defaultValue={"10"}
                                placeholder="EV battery buffer"
                                value={
                                  newTerminalScenario?.fleetMix?.evBatteryBuffer
                                }
                                onChange={(e) => {
                                  if (Number(e.target.value) > 100) {
                                    return;
                                  }
                                  setNewTerminalScenario((prevData: any) => ({
                                    ...prevData,
                                    fleetMix: {
                                      ...prevData.fleetMix,
                                      evBatteryBuffer: Number(e.target.value),
                                    },
                                  }));
                                }}
                                type="number"
                                inputProps={{ step: 1, min: 0, max: 100 }}
                                InputLabelProps={{
                                  shrink: true,
                                  style: { fontSize: "11px" },
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="In Diesel-Gallon Equivalent per hour">
                              <TextField
                                label="ICE Vehicle Fuel Consumption"
                                sx={{
                                  width: "30%",
                                }}
                                size="small"
                                required
                                placeholder="Diesel-Gallons per hour"
                                value={
                                  newTerminalScenario?.fleetMix
                                    ?.iceVehicleFuelConsumption
                                }
                                onChange={(e) =>
                                  setNewTerminalScenario((prevData: any) => ({
                                    ...prevData,
                                    fleetMix: {
                                      ...prevData.fleetMix,
                                      iceVehicleFuelConsumption:
                                        getCorrectFloat(
                                          e.target.value,
                                          0,
                                          Infinity
                                        ),
                                    },
                                  }))
                                }
                                type="number"
                                InputLabelProps={{
                                  shrink: true,
                                  style: { fontSize: "11px" },
                                }}
                              />
                            </Tooltip>
                            <TextField
                              label={"Existing ICE Fleet Size"}
                              type="number"
                              value={newTerminalScenario?.fleetMix?.fleetSize}
                              sx={{ width: "22%" }}
                              size="small"
                              error={configurationError.fleetSize}
                              onChange={(e) => {
                                setConfigurationError({
                                  ...configurationError,
                                  fleetSize: false,
                                  isError: false,
                                  message: "",
                                });
                                handleExistingFleetSizeChange(e);
                              }}
                              required
                              InputLabelProps={{
                                shrink: true,
                                style: { fontSize: "11px" },
                              }}
                            />
                            <TextField
                              label={"Number per Shift"}
                              type="number"
                              value={
                                newTerminalScenario?.fleetMix?.vehiclesPerShift
                              }
                              sx={{ width: "25%" }}
                              size="small"
                              error={configurationError.vehiclesPerShift}
                              onChange={(e) => {
                                setConfigurationError({
                                  ...configurationError,
                                  vehiclesPerShift: false,
                                  isError: false,
                                  message: "",
                                });
                                handleVehiclesPerShiftChange(e);
                              }}
                              required
                              InputLabelProps={{
                                shrink: true,
                                style: { fontSize: "11px" },
                              }}
                            />
                            <TextField
                              label="ICE Reference Vehicle"
                              value={vehicleInfo.iceReferenceVehicle}
                              sx={{
                                width: { md: "34%", lg: "30%" },
                              }}
                              size="small"
                              InputLabelProps={{
                                shrink: true,
                                style: { fontSize: "11px" },
                              }}
                            />
                            <div style={{ width: "20%" }} />
                          </Stack>
                          {configurationError.isError &&
                            configurationError.message && (
                              <Stack>
                                <Typography
                                  sx={{ marginTop: -1 }}
                                  variant="caption"
                                  style={{ color: "#d32f2f" }}
                                >
                                  {configurationError.message}
                                </Typography>
                              </Stack>
                            )}
                        </>
                      )}

                      {step !== 1 && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          alignContent="space-between"
                          spacing={23}
                        >
                          <Typography variant="h3">
                            {step === 2
                              ? "Vehicle Information – EV Options"
                              : step === 3
                              ? "Charger Information – Options"
                              : "Utility Rate Components"}
                          </Typography>
                          {step === 3 && (
                            <Typography variant="h3" color="secondary">
                              Please note: All charger-vehicle combinations
                              should be validated for compatibility prior to
                              purchase.
                            </Typography>
                          )}
                        </Stack>
                      )}

                      {step === 4 && (
                        <Stack direction={"column"} spacing={2}>
                          <Stack direction="row">
                            <RadioGroup
                              row
                              value={utilityConfig}
                              onChange={(event) => {
                                setUtilityRateUploadData({
                                  name: "",
                                  demandCharges: [],
                                  demandRates: [],
                                  energyCharges: [],
                                  energyRates: [],
                                  fixedMonthlyFees: 0,
                                });
                                setUtilityConfig(event.target.value);
                                if (event.target.value === "new") {
                                  setRateConfirmation(false);
                                  return;
                                }
                                if (selectedUtilityRate)
                                  setUtilityInformation(selectedUtilityRate);
                              }}
                            >
                              <FormControlLabel
                                value="new"
                                control={<Radio />}
                                label="Create a utility rate"
                              />
                              <FormControlLabel
                                value="use"
                                control={<Radio />}
                                label="Use existing utility rate"
                              />
                            </RadioGroup>
                          </Stack>
                          {utilityConfig === "use" && (
                            <Stack spacing={2} direction={"row"}>
                              <FormControl
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "20%",
                                }}
                              >
                                {utilityRates.length !== 0 && (
                                  <>
                                    <InputLabel>
                                      List of Utility Rates
                                    </InputLabel>
                                    <Select
                                      label="List of utility rates"
                                      value={utilityInformation.id}
                                      onChange={(event) => {
                                        const utility = utilityRates.find(
                                          (u) =>
                                            u.id === Number(event.target.value)
                                        );
                                        if (utility) {
                                          setUtilityInformation(utility);
                                          setRateConfirmation(false);
                                          setSelectedUtilityRate(utility);
                                        }
                                      }}
                                    >
                                      {utilityRates?.map((utilityRate) => (
                                        <MenuItem
                                          key={utilityRate.id}
                                          value={utilityRate.id}
                                        >
                                          {utilityRate.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </>
                                )}
                              </FormControl>
                              <LoadingButton
                                sx={{ marginLeft: 2, padding: 1 }}
                                color="primary"
                                variant="contained"
                                loading={loadingDefaultUtilityRate}
                                onClick={setDefaultUtilityRateAPI}
                              >
                                Set as default rate
                              </LoadingButton>
                            </Stack>
                          )}
                          {utilityConfig === "new" && (
                            <Stack spacing={2} direction={"row"}>
                              <Box sx={{ width: "20%" }}>
                                <input
                                  ref={utilityFileElement}
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={handleFileUpload}
                                  accept=".xlsx, .xls"
                                />
                                <Button
                                  sx={{ padding: 1, width: "100%" }}
                                  color="primary"
                                  variant="contained"
                                  onClick={handleUploadFileClick}
                                >
                                  Upload utility rate
                                </Button>
                              </Box>
                              <Button
                                sx={{ padding: 1, width: "20%" }}
                                color="primary"
                                variant="contained"
                                onClick={() =>
                                  downloadUtilityRate(
                                    SAMPLE_UTILITY_RATE,
                                    "utility-rate-template.xlsx"
                                  )
                                }
                              >
                                Download rate template
                              </Button>

                              <Button
                                sx={{ padding: 1, width: "20%" }}
                                variant="contained"
                                color="secondary"
                                onClick={() =>
                                  downloadUtilityRate(
                                    PG_AND_E_UTILITY_RATE,
                                    "sample-utility-rates.xlsx"
                                  )
                                }
                              >
                                Download Sample Rate
                              </Button>
                            </Stack>
                          )}
                          {utilityConfig === "new" &&
                            configurationError.utilityFile &&
                            configurationError.message && (
                              <Stack>
                                <Typography
                                  sx={{ marginTop: -1 }}
                                  variant="caption"
                                  style={{ color: "#d32f2f" }}
                                >
                                  {configurationError.message}
                                </Typography>
                              </Stack>
                            )}
                          <Stack marginTop={2} spacing={2} direction={"row"}>
                            <TextField
                              label={"Utility Name"}
                              disabled={utilityConfig === "use"}
                              value={utilityInformation.name}
                              onChange={(e) => {
                                const { value } = e.target;
                                setConfigurationError({
                                  ...configurationError,
                                  message: "",
                                  utilityName: false,
                                  rateConfirm: false,
                                });
                                setUtilityInformation((prevState) => ({
                                  ...prevState,
                                  name: value,
                                }));
                                const values = utilityRateUploadData;
                                values.name = value || "";
                                setUtilityRateUploadData(values);
                                setRateConfirmation(false);
                              }}
                              sx={{
                                marginLeft: 0,
                                width: "20%",
                                padding: 0,
                              }}
                              size="small"
                              type="text"
                              error={
                                configurationError.utilityName &&
                                utilityConfig === "new"
                              }
                              helperText={
                                configurationError.utilityName &&
                                utilityConfig === "new"
                                  ? configurationError.message
                                  : ""
                              }
                              InputLabelProps={{
                                shrink: true,
                                style: { fontSize: "14px" },
                              }}
                            />
                            <TextField
                              label={"Fixed Monthly Fees"}
                              value={utilityInformation.fixedMonthlyFees}
                              disabled={true}
                              onChange={(event) => {
                                const { value } = event.target;
                                setUtilityInformation((prevState) => ({
                                  ...prevState,
                                  fixedMonthlyFees: Number(value),
                                }));
                                setConfigurationError({
                                  ...configurationError,
                                  rateConfirm: false,
                                });
                                setRateConfirmation(false);
                              }}
                              sx={{
                                width: "20%",
                                padding: 0,
                              }}
                              size="small"
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                                style: { fontSize: "14px" },
                              }}
                            />
                            <FormControl
                              variant="outlined"
                              sx={{ width: "36%" }}
                              size="small"
                            >
                              <InputLabel id="multi-select-checkbox-label">
                                Which month is this configuration likely to
                                occur?
                              </InputLabel>
                              <Select
                                labelId="multi-select-checkbox-month"
                                value={
                                  newTerminalScenario.rateAppliedMonth || 1
                                }
                                onChange={(event) =>
                                  setNewTerminalScenario((prevData: any) => ({
                                    ...prevData,
                                    rateAppliedMonth: Number(
                                      event.target.value
                                    ),
                                  }))
                                }
                                label="Which month is this configuration likely to occur?"
                              >
                                {MONTH.map((month, index) => (
                                  <MenuItem key={month} value={index + 1}>
                                    {month}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Stack>
                        </Stack>
                      )}

                      {step !== 1 && step !== 4 && (
                        <>
                          <TableContainer
                            component={Paper}
                            sx={{
                              maxHeight: step === 4 ? 230 : 276,
                              minHeight: step === 4 ? 165 : 240,
                              overflowY: "auto",
                            }}
                          >
                            <Table
                              sx={{
                                minWidth: 650,
                              }}
                            >
                              <TableHead
                                sx={{
                                  background: "#222222",
                                  position: "sticky",
                                  top: 0,
                                  zIndex: 1,
                                }}
                              >
                                <TableRow sx={{ color: "#fff" }}>
                                  {tableColumns[
                                    step.toString() as string as keyof typeof tableColumns
                                  ]?.map((column, index) => (
                                    <TableCell
                                      key={index}
                                      sx={{ color: "#fff" }}
                                      align={
                                        column === "Action" ? "center" : "left"
                                      }
                                    >
                                      {column}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {step !== 4 && editMode && (
                                  <TableRow>
                                    {[...Array(6)].map((_, columnIndex) =>
                                      columnIndex === 1 && step === 3 ? null : (
                                        <TableCell key={columnIndex}>
                                          {step === 3 && columnIndex === 0 ? (
                                            <Stack direction="row" spacing={3}>
                                              <Input
                                                value={
                                                  newRowData[columnIndex] || ""
                                                }
                                                placeholder="Make"
                                                type="string"
                                                onChange={(e) =>
                                                  handleInputChangeForRowData(
                                                    e,
                                                    columnIndex
                                                  )
                                                }
                                              />
                                              <Input
                                                value={
                                                  newRowData[columnIndex + 1] ||
                                                  ""
                                                }
                                                placeholder="Model"
                                                type="string"
                                                onChange={(e) =>
                                                  handleInputChangeForRowData(
                                                    e,
                                                    columnIndex + 1
                                                  )
                                                }
                                              />
                                            </Stack>
                                          ) : step === 2 &&
                                            columnIndex === 5 ? (
                                            <Switch
                                              onChange={(e) =>
                                                handleInputChangeForRowData(
                                                  e,
                                                  columnIndex
                                                )
                                              }
                                            />
                                          ) : (
                                            <Input
                                              value={
                                                newRowData[columnIndex] || ""
                                              }
                                              placeholder={
                                                tableColumns[
                                                  step.toString() as keyof typeof tableColumns
                                                ][
                                                  columnIndex -
                                                    (step === 3 &&
                                                    columnIndex > 1
                                                      ? 1
                                                      : 0)
                                                ]
                                              }
                                              type={
                                                columnIndex === 0 ||
                                                (step === 2 &&
                                                  columnIndex === 1)
                                                  ? "string"
                                                  : "number"
                                              }
                                              onChange={(e) =>
                                                handleInputChangeForRowData(
                                                  e,
                                                  columnIndex
                                                )
                                              }
                                            />
                                          )}
                                        </TableCell>
                                      )
                                    )}
                                  </TableRow>
                                )}

                                {table1Data &&
                                  table1Data[step - 2]?.map((column, index) => (
                                    <TableRow key={index}>
                                      {column?.map(
                                        (value: any, columnIndex: number) =>
                                          (step === 4 ||
                                            (step !== 4 &&
                                              columnIndex !== 0)) && (
                                            <TableCell
                                              key={columnIndex}
                                              align="left"
                                            >
                                              {step === 4 &&
                                              editMode &&
                                              columnIndex !== 0 ? (
                                                <Input
                                                  inputProps={{ step: "0.01" }}
                                                  value={value}
                                                  type="number"
                                                  onChange={(e) =>
                                                    handleInputChange(
                                                      e.target.value,
                                                      index,
                                                      columnIndex,
                                                      setTable1Data,
                                                      "table1"
                                                    )
                                                  }
                                                />
                                              ) : step === 4 &&
                                                columnIndex !== 0 ? (
                                                index === 1 ? (
                                                  `${value}($/KW)`
                                                ) : (
                                                  `${value}($/KWH)`
                                                )
                                              ) : ((step === 2 &&
                                                  columnIndex === 5) ||
                                                  (step === 3 &&
                                                    columnIndex === 4) ||
                                                  columnIndex === 5) &&
                                                editPrice === index ? (
                                                <Input
                                                  value={
                                                    step === 3 &&
                                                    columnIndex === 5
                                                      ? newInstallationCost
                                                      : newPrice
                                                  }
                                                  type="number"
                                                  onChange={(e) => {
                                                    const inputValue = Number(
                                                      e.target.value
                                                    );
                                                    const safeValue = Math.max(
                                                      0,
                                                      inputValue
                                                    );

                                                    if (
                                                      step === 3 &&
                                                      columnIndex === 5
                                                    ) {
                                                      setNewInstallationCost(
                                                        safeValue
                                                      );
                                                    } else {
                                                      setNewPrice(safeValue);
                                                    }
                                                  }}
                                                />
                                              ) : step === 2 &&
                                                columnIndex === 6 ? (
                                                editPrice === index ? (
                                                  <Switch
                                                    defaultChecked={value}
                                                    onChange={(e) => {
                                                      table1Data[step - 2][
                                                        index
                                                      ][6] = e.target.checked;
                                                      setTable1Data(table1Data);
                                                    }}
                                                  />
                                                ) : value === true ? (
                                                  <Icon color="success">
                                                    <Check />
                                                  </Icon>
                                                ) : (
                                                  <Icon color="error">
                                                    <Close />
                                                  </Icon>
                                                )
                                              ) : isNaN(+value) ? (
                                                value
                                              ) : (
                                                (+value).toLocaleString()
                                              )}
                                            </TableCell>
                                          )
                                      )}
                                      {step !== 4 && (
                                        <TableCell
                                          align="center"
                                          sx={{ minWidth: "80px" }}
                                        >
                                          {deleteTableEntry === index ||
                                          editPrice === index ? (
                                            <>
                                              <Tooltip
                                                placement="top"
                                                title={
                                                  editPrice === index
                                                    ? "Save"
                                                    : "Confirm deletion"
                                                }
                                              >
                                                <IconButton
                                                  color="primary"
                                                  onClick={() => {
                                                    if (editPrice === index) {
                                                      handleEditPrice(index);
                                                    } else {
                                                      handleDeleteTableEntry(
                                                        index
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <Check
                                                    sx={{ fontSize: "15px" }}
                                                  />
                                                </IconButton>
                                              </Tooltip>
                                              <Tooltip
                                                placement="top"
                                                title="cancel"
                                              >
                                                <IconButton
                                                  color="error"
                                                  onClick={() => {
                                                    setEditPrice(null);
                                                    setNewPrice(null);
                                                    setNewInstallationCost(
                                                      null
                                                    );
                                                    setDeleteTableEntry(null);
                                                  }}
                                                >
                                                  <Close
                                                    sx={{ fontSize: "15px" }}
                                                  />
                                                </IconButton>
                                              </Tooltip>
                                            </>
                                          ) : (
                                            <>
                                              <Tooltip
                                                title="Edit"
                                                placement="top"
                                              >
                                                <IconButton
                                                  sx={{ color: "grey" }}
                                                  onClick={() => {
                                                    setEditPrice(index);
                                                    setNewPrice(
                                                      step === 2
                                                        ? column[5]
                                                        : column[4]
                                                    );
                                                    setNewInstallationCost(
                                                      column[5]
                                                    );
                                                  }}
                                                >
                                                  <Edit />
                                                </IconButton>
                                              </Tooltip>
                                              <IconButton
                                                color="error"
                                                onClick={() =>
                                                  setDeleteTableEntry(index)
                                                }
                                              >
                                                <Delete />
                                              </IconButton>
                                            </>
                                          )}
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>

                          {step !== 4 && (
                            <Stack direction="row" spacing={1}>
                              {editMode && (
                                <Button
                                  variant="contained"
                                  sx={{
                                    width: "10%",
                                    background: "#d3494e",
                                    "&:hover": {
                                      background: "#96212c",
                                    },
                                  }}
                                  onClick={handleCancelNewEntry}
                                >
                                  Cancel
                                </Button>
                              )}
                              <Button
                                variant="contained"
                                sx={{
                                  width: editMode ? "10%" : "20%",
                                  background: editMode ? "#1DC487" : "06c2cc",
                                  "&:hover": {
                                    background: editMode ? "#1d8e65" : "0aa0a8",
                                  },
                                }}
                                disabled={
                                  (editMode &&
                                    newRowData.some(
                                      (value) => value === undefined
                                    )) ||
                                  (step === 2 &&
                                    newTerminalScenario?.fleetMix?.evOptions
                                      ?.length === 6) ||
                                  (step === 3 &&
                                    newTerminalScenario?.chargerOptions
                                      ?.length === 6)
                                }
                                onClick={() => {
                                  setConfigurationError({
                                    ...configurationError,
                                    evOptions: false,
                                    isError: false,
                                    message: "",
                                    chargerOptions: false,
                                  });
                                  setEditMode(!editMode);
                                  if (step === 2) {
                                    setNewRowData((prevRowData) => {
                                      const updatedRowData = [...prevRowData];
                                      updatedRowData[5] = false;
                                      return updatedRowData;
                                    });
                                  }
                                  if (editMode) {
                                    handleSaveNewEntry();
                                  }
                                }}
                              >
                                {!editMode
                                  ? step === 2
                                    ? "Add vehicle"
                                    : "Add Charger"
                                  : "Save"}
                              </Button>
                            </Stack>
                          )}
                          {configurationError.isError &&
                            configurationError.message && (
                              <Stack>
                                <Typography
                                  sx={{ marginTop: -1 }}
                                  variant="caption"
                                  style={{ color: "#d32f2f" }}
                                >
                                  {configurationError.message}
                                </Typography>
                              </Stack>
                            )}
                        </>
                      )}

                      {step === 4 && (
                        <Section>
                          <Typography fontWeight={700}>
                            Fixed charge ($/month)
                          </Typography>
                          <Stack direction={"row"} columnGap={2}>
                            <Section>
                              <Stack
                                direction={"column"}
                                height={"100%"}
                                justifyContent={"space-between"}
                              >
                                <Box>
                                  <Typography marginBottom={2}>
                                    Rates for Energy Charges
                                  </Typography>
                                  <Table
                                    sx={{
                                      border: "1px solid #e0e0e0",
                                      width: "100%",
                                    }}
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Period</TableCell>
                                        <TableCell>Tier</TableCell>
                                        <TableCell>Buy ($/kWh)</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {selectedUtilityRate?.energyRates.map(
                                        (energy, index) => (
                                          <TableRow key={index}>
                                            <TableCell>
                                              {energy.period}
                                            </TableCell>
                                            <TableCell>{energy.tier}</TableCell>
                                            <TableCell>
                                              {energy.rates}
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                </Box>

                                <Table
                                  size="small"
                                  sx={{
                                    marginTop: 2,
                                    border: "1px solid #ddd",
                                  }}
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell
                                        sx={{ width: 28, height: 28 }}
                                      ></TableCell>
                                      {Array.from(
                                        { length: 24 },
                                        (_, index) => (
                                          <TableCell
                                            key={index}
                                            align="center"
                                            sx={{
                                              transform: "rotate(-90deg)",
                                              fontSize: "10px",
                                              width: 28,
                                              height: 28,
                                              padding: 0,
                                              borderBottom: "none",
                                            }}
                                          >
                                            {index === 0
                                              ? "12AM"
                                              : index < 12
                                              ? `${index}AM`
                                              : `${index - 12 || 12}PM`}
                                          </TableCell>
                                        )
                                      )}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {selectedUtilityRate?.energyCharges.map(
                                      (rates, index) => (
                                        <TableRow key={index}>
                                          <TableCell
                                            sx={{
                                              fontSize: "10px",
                                              textAlign: "center",
                                              padding: 0,
                                            }}
                                          >
                                            {MONTHSHORT[index]}
                                          </TableCell>
                                          {rates.map((rate, rateIndex) => (
                                            <TableCell
                                              key={rateIndex}
                                              sx={{
                                                padding: 0,
                                                width: 28,
                                                height: 28,
                                                textAlign: "center",
                                              }}
                                            >
                                              <Typography
                                                variant="body2"
                                                sx={{
                                                  fontSize: "10px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                {rate}
                                              </Typography>
                                            </TableCell>
                                          ))}
                                        </TableRow>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              </Stack>
                            </Section>

                            <Section>
                              <Stack
                                direction={"column"}
                                height={"100%"}
                                justifyContent={"space-between"}
                              >
                                <Box>
                                  <Typography marginBottom={2}>
                                    Rates for Demand Charges
                                  </Typography>
                                  <Table
                                    sx={{
                                      border: "1px solid #e0e0e0",
                                      width: "100%",
                                    }}
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Period</TableCell>
                                        <TableCell>Tier</TableCell>
                                        <TableCell>Charge ($/kW)</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {selectedUtilityRate?.demandRates.map(
                                        (energy, index) => (
                                          <TableRow key={index}>
                                            <TableCell>
                                              {energy.period}
                                            </TableCell>
                                            <TableCell>{energy.tier}</TableCell>
                                            <TableCell>
                                              {energy.rates}
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                </Box>

                                <Table
                                  size="small"
                                  sx={{
                                    marginTop: 2,
                                    border: "1px solid #ddd",
                                  }}
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell
                                        sx={{ width: 28, height: 28 }}
                                      ></TableCell>
                                      {Array.from(
                                        { length: 24 },
                                        (_, index) => (
                                          <TableCell
                                            key={index}
                                            align="center"
                                            sx={{
                                              transform: "rotate(-90deg)",
                                              fontSize: "10px",
                                              width: 28,
                                              height: 28,
                                              padding: 0,
                                              borderBottom: "none",
                                            }}
                                          >
                                            {index === 0
                                              ? "12AM"
                                              : index < 12
                                              ? `${index}AM`
                                              : `${index - 12 || 12}PM`}
                                          </TableCell>
                                        )
                                      )}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {selectedUtilityRate?.demandCharges.map(
                                      (rates, index) => (
                                        <TableRow key={index}>
                                          <TableCell
                                            sx={{
                                              fontSize: "10px",
                                              textAlign: "center",
                                              padding: 0,
                                            }}
                                          >
                                            {MONTHSHORT[index]}
                                          </TableCell>
                                          {rates.map((rate, rateIndex) => (
                                            <TableCell
                                              key={rateIndex}
                                              sx={{
                                                padding: 0,
                                                width: 28,
                                                height: 28,
                                                textAlign: "center",
                                              }}
                                            >
                                              <Typography
                                                variant="body2"
                                                sx={{
                                                  fontSize: "10px",
                                                  textAlign: "center",
                                                }}
                                              >
                                                {rate}
                                              </Typography>
                                            </TableCell>
                                          ))}
                                        </TableRow>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              </Stack>
                            </Section>
                          </Stack>
                        </Section>
                      )}

                      {step === 2 && (
                        <Stack>
                          <Stack direction={"row"} pt={2} spacing={2}>
                            <Tooltip title="Percent of time the vehicle is out for repair or maintenance">
                              <TextField
                                label="ICE Vehicle Downtime (%)​"
                                value={
                                  newTerminalScenario?.fleetMix
                                    ?.iceVehicleDowntime
                                }
                                onChange={(e) => {
                                  setConfigurationError({
                                    ...configurationError,
                                    downTime: false,
                                  });
                                  setNewTerminalScenario((prevData: any) => ({
                                    ...prevData,
                                    fleetMix: {
                                      ...prevData.fleetMix,
                                      iceVehicleDowntime: getCorrectInt(
                                        e.target.value ?? "",
                                        0,
                                        99
                                      ),
                                    },
                                  }));
                                }}
                                sx={{ width: "20%" }}
                                size="small"
                                type="number"
                                InputLabelProps={{
                                  shrink: true,
                                  style: { fontSize: "11px" },
                                }}
                                error={configurationError.downTime}
                                helperText={
                                  configurationError.downTime
                                    ? "ICE fleet too small for entered ICE vehicles per shift and downtime requirements"
                                    : ""
                                }
                                FormHelperTextProps={{
                                  sx: { marginLeft: 0, width: "500px" },
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Percent of time the vehicle is out for repair or maintenance">
                              <TextField
                                label="EV Downtime (%)"
                                value={
                                  newTerminalScenario?.fleetMix
                                    ?.evExpectedDowntime
                                }
                                onChange={(e) => {
                                  setNewTerminalScenario((prevData: any) => ({
                                    ...prevData,
                                    fleetMix: {
                                      ...prevData.fleetMix,
                                      evExpectedDowntime: getCorrectInt(
                                        e.target.value ?? "",
                                        0,
                                        99
                                      ),
                                    },
                                  }));
                                }}
                                sx={{ width: "20%" }}
                                size="small"
                                type="number"
                                required
                                InputLabelProps={{
                                  shrink: true,
                                  style: { fontSize: "11px" },
                                }}
                              />
                            </Tooltip>

                            <Tooltip title="Replacement cycle for equipment in years">
                              <TextField
                                label="EV Replacement (years)​"
                                value={
                                  newTerminalScenario?.fleetMix
                                    ?.vehicleReplacementLifecycleYears
                                }
                                onChange={(e) =>
                                  handleChangeVehicleReplacementLifecycle(e)
                                }
                                sx={{ width: "19%" }}
                                size="small"
                                type="number"
                                InputLabelProps={{
                                  shrink: true,
                                  style: { fontSize: "11px" },
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Replacement cycle for equipment in years">
                              <TextField
                                label="Battery Replacement Lifecycle"
                                value={
                                  newTerminalScenario?.fleetMix
                                    ?.batteryReplacementLifecycleYears
                                }
                                onChange={(e) =>
                                  setNewTerminalScenario((prevData: any) => ({
                                    ...prevData,
                                    fleetMix: {
                                      ...prevData.fleetMix,
                                      batteryReplacementLifecycleYears:
                                        getCorrectInt(e.target.value, 0, 24),
                                    },
                                  }))
                                }
                                onBlur={handleChangeBatteryReplacementLifecycle}
                                sx={{ width: "23%" }}
                                size="small"
                                type="number"
                                InputLabelProps={{
                                  shrink: true,
                                  style: { fontSize: "11px" },
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Annual EV vehicle maintenance as % of equipment cost">
                              <TextField
                                label="EV Vehicle Maintenance (%)"
                                value={
                                  newTerminalScenario?.fleetMix
                                    ?.vehicleMaintenanceCostPct
                                }
                                onChange={(e) => {
                                  setNewTerminalScenario((prevData: any) => ({
                                    ...prevData,
                                    fleetMix: {
                                      ...prevData.fleetMix,
                                      vehicleMaintenanceCostPct: getCorrectInt(
                                        e.target.value ?? "",
                                        0,
                                        Infinity
                                      ),
                                    },
                                  }));
                                }}
                                sx={{ width: "20%" }}
                                size="small"
                                type="number"
                                inputProps={{ step: 1, min: 0, max: 100 }}
                                InputLabelProps={{
                                  shrink: true,
                                  style: { fontSize: "11px" },
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Annual ICE vehicle maintenance as % of equipment cost">
                              <TextField
                                label="ICE Vehicle Maintenance (%)"
                                value={
                                  newTerminalScenario?.fleetMix
                                    ?.iceVehicleMaintenanceCostPct
                                }
                                onChange={(e) => {
                                  setNewTerminalScenario((prevData: any) => ({
                                    ...prevData,
                                    fleetMix: {
                                      ...prevData.fleetMix,
                                      iceVehicleMaintenanceCostPct:
                                        getCorrectInt(
                                          e.target.value ?? "",
                                          0,
                                          Infinity
                                        ),
                                    },
                                  }));
                                }}
                                sx={{ width: "20%" }}
                                size="small"
                                type="number"
                                inputProps={{ step: 1, min: 0, max: 100 }}
                                InputLabelProps={{
                                  shrink: true,
                                  style: { fontSize: "11px" },
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Fuel cost per gallon">
                              <TextField
                                label="ICE Fuel cost*​"
                                value={
                                  newTerminalScenario?.iceFuelCostPerGallon
                                }
                                sx={{ width: "12%" }}
                                size="small"
                                type="number"
                                InputLabelProps={{
                                  shrink: true,
                                  style: { fontSize: "11px" },
                                }}
                                onChange={(e) => {
                                  const intValue = getCorrectInt(
                                    e.target.value ?? "",
                                    0,
                                    Infinity
                                  );
                                  setNewTerminalScenario((prevData: any) => ({
                                    ...prevData,
                                    iceFuelCostPerGallon: intValue,
                                  }));
                                }}
                              />
                            </Tooltip>
                          </Stack>
                          {configurationError.isWarning && (
                            <Stack>
                              <Typography
                                variant="caption"
                                style={{ color: "#ff9800" }}
                                mt={2}
                              >
                                {
                                  "Your ICE fleet size surpasses the operational requirements for the shift, inclusive of anticipated downtime"
                                }
                              </Typography>
                            </Stack>
                          )}
                          {errorMessage && (
                            <Stack>
                              <Typography
                                variant="caption"
                                style={{ color: "#ff9800" }}
                                mt={2}
                              >
                                {errorMessage}
                              </Typography>
                            </Stack>
                          )}
                        </Stack>
                      )}

                      {step === 3 && (
                        <Stack direction={"row"} pt={2} spacing={2}>
                          <Tooltip title="Replacement cycle for equipment in years">
                            <TextField
                              label="Charger Replacement Lifecycle"
                              value={
                                newTerminalScenario?.chargerReplacementLifecycleYears
                              }
                              onChange={(e) =>
                                setNewTerminalScenario((prevData: any) => ({
                                  ...prevData,
                                  chargerReplacementLifecycleYears:
                                    getCorrectInt(e.target.value, 0, 24),
                                }))
                              }
                              sx={{ width: "20%" }}
                              size="small"
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                                style: { fontSize: "12px" },
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Annual maintenance as % of equipment cost">
                            <TextField
                              label="Charger Maintenance (%)"
                              value={
                                newTerminalScenario?.chargerMaintenanceCostPct
                              }
                              onChange={(e) => {
                                setNewTerminalScenario((prevData: any) => ({
                                  ...prevData,
                                  chargerMaintenanceCostPct: getCorrectInt(
                                    e.target.value ?? "",
                                    0,
                                    100
                                  ),
                                }));
                              }}
                              sx={{ width: "20%" }}
                              size="small"
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                                style: { fontSize: "12px" },
                              }}
                            />
                          </Tooltip>

                          <TextField
                            label="Existing Charger Count"
                            value={newTerminalScenario?.numExistingChargers}
                            onChange={(e) => {
                              setNewTerminalScenario((prevData: any) => ({
                                ...prevData,
                                numExistingChargers: getCorrectInt(
                                  e.target.value ?? "",
                                  0,
                                  Infinity
                                ),
                              }));
                            }}
                            sx={{ width: "20%" }}
                            size="small"
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                              style: { fontSize: "12px" },
                            }}
                          />

                          <TextField
                            label="Existing Charger Rate (kW)"
                            value={
                              newTerminalScenario?.existingChargerChargeRate
                            }
                            onChange={(e) => {
                              setNewTerminalScenario((prevData: any) => ({
                                ...prevData,
                                existingChargerChargeRate: getCorrectInt(
                                  e.target.value ?? "",
                                  0,
                                  Infinity
                                ),
                              }));
                            }}
                            sx={{ width: "20%" }}
                            size="small"
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                              style: { fontSize: "12px" },
                            }}
                          />
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </DialogContent>

            <DialogActions>
              <Stack
                width="100%"
                p={2}
                direction="row"
                justifyContent={"space-between"}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleBack}
                  disabled={step === 1}
                >
                  Go Back
                </Button>
                {step === 4 ? (
                  <Stack spacing={2} direction={"row"}>
                    {configurationError.rateConfirm && (
                      <Typography
                        sx={{ marginTop: 1 }}
                        variant="body2"
                        style={{ color: "#d32f2f" }}
                      >
                        {
                          "Please confirm the rate before creating the scenario."
                        }
                      </Typography>
                    )}
                    <Button
                      variant="contained"
                      onClick={handleRateConfirmationClick}
                    >
                      Confirm rate applied
                    </Button>
                    <Tooltip title={submitButtonTooltip} placement={"left"}>
                      <span>
                        <LoadingButton
                          className="submit-button"
                          variant="contained"
                          onClick={handleSubmit}
                          loading={loading}
                          disabled={disableSubmitBtn}
                        >
                          Finish
                        </LoadingButton>
                      </span>
                    </Tooltip>
                  </Stack>
                ) : (
                  <Button
                    variant="contained"
                    sx={{
                      px: 4,
                      bgcolor: "#097969",
                      "&:hover": {
                        bgcolor: "#088F8F",
                      },
                    }}
                    onClick={handleNext}
                    disabled={step === 2 && configurationError.isError}
                  >
                    Next
                  </Button>
                )}
              </Stack>
            </DialogActions>
          </>
        )}
      </Dialog>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        onClose={() => setShowSnackbar(false)}
      >
        <Alert severity={snackbarType}>{snackbarMessage}</Alert>
      </Snackbar>
    </>
  );
}
