import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";

export interface RiskAssessmentDialogProps {
  isOpen: boolean;
  handleClose: () => void;
}

export function RiskAssessment() {
  return (
    <>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                sx={{
                  backgroundColor: "#05C2CC",
                  borderRight: "1px solid #E0E0DE",
                  color: "#FFFFFF",
                }}
                colSpan={2}
              >
                Attribute
              </TableCell>
              <TableCell
                align="center"
                sx={{ backgroundColor: "#05C2CC", color: "#FFFFFF" }}
              >
                Score
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                sx={{ backgroundColor: "#05C2CC", color: "#FFFFFF" }}
                align="left"
                width={"25%"}
              >
                Fleet Characteristics
              </TableCell>
              <TableCell align="left" width={"20%"}>
                <Stack direction={"column"} spacing={2}>
                  <Typography variant="body2">Vehicle Type</Typography>
                  <Divider />
                  <Typography variant="body2">Daily Distance</Typography>
                  <Divider />
                  <Typography variant="body2">Operating Hours</Typography>
                  <Divider />
                  <Typography variant="body2">Terrain</Typography>
                  <Divider />
                  <Typography variant="body2">Charging</Typography>
                </Stack>
              </TableCell>
              <TableCell align="left" width={"55%"}>
                <Stack direction={"column"} spacing={2}>
                  <Typography variant="body2">
                    {
                      "5 for Passenger >= 50%, 4 for Truck >= 50%, 3 for Vocational >= 50%, Otherwise 4"
                    }
                  </Typography>
                  <Divider />
                  <Typography variant="body2">
                    {"5 for < 50mi/day, 3 for +- 100mi/day, 1 for > 200mi/day"}
                  </Typography>
                  <Divider />
                  <Typography variant="body2">
                    {"5 for < 8hrs/day, 3 for +- 12hrs/day, 1 for > 12hrs/day "}
                  </Typography>
                  <Divider />
                  <Typography variant="body2">
                    {"5 for city, 4 for off-road, 3 for highway"}
                  </Typography>
                  <Divider />
                  <Typography variant="body2">
                    {"5 for at facility, 3 for in public"}
                  </Typography>
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ backgroundColor: "#05C2CC", color: "#FFFFFF" }}>
                Operations type
              </TableCell>
              <TableCell>Common Daily Routes</TableCell>
              <TableCell>
                {"5 for common routes, 1 for no common routes"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ backgroundColor: "#05C2CC", color: "#FFFFFF" }}>
                Depot details
              </TableCell>
              <TableCell>Parking</TableCell>
              <TableCell>{"5 for owned, 1 for leased"}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ backgroundColor: "#05C2CC", color: "#FFFFFF" }}>
                Attitude to EV
              </TableCell>
              <TableCell sx={{ width: 10 }}>Organization</TableCell>
              <TableCell>
                {"5 is stakeholders aligned, 3 if interested, 1 if champion"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ backgroundColor: "#05C2CC", color: "#FFFFFF" }}>
                Total Score (out of 40 points)
              </TableCell>
              <TableCell>Risk Assessment</TableCell>
              <TableCell>
                {
                  " > 26 for low risk, 13-26 for medium risk, < 13 for high risk"
                }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography
        sx={{ marginTop: 1 }}
        variant="body1"
        style={{ color: "#ed8e1a" }}
      >
        Note: Insufficient charging time, insufficient downtime, energy
        requirements over 1 MWh, or tractor-trailer electrification leads to a
        high risk assessment.
      </Typography>
    </>
  );
}

export default function RiskAssessmentDialog({
  isOpen,
  handleClose,
}: RiskAssessmentDialogProps) {
  return (
    <>
      {/* Delete Fleet Scenario Popup */}
      <Dialog
        open={isOpen}
        onClose={() => handleClose()}
        maxWidth="lg"
        fullWidth
      >
        <IconButton
          aria-label="close"
          onClick={() => handleClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 16,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <GridCloseIcon />
        </IconButton>
        <DialogTitle id="alert-dialog-title">
          {"Risk Assessment Calculation"}
        </DialogTitle>
        <DialogContent>
          <RiskAssessment />
        </DialogContent>
      </Dialog>
    </>
  );
}
